import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import { useSignUpPageData, Header } from 'sections/signUpPage';
import { useKisSignUpPageData } from 'graphqlHooks';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// Data

const SignUpPage = () => {
  const { seo, errorMessage } = useSignUpPageData();
  const { header: cmsHeader } = useKisSignUpPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} errorMessage={errorMessage} />
    </>
  );
};

export default injectIntl(SignUpPage);
