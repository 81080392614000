import { object, string } from 'yup';

export const getContactFormValidationSchema = (errorMessage) => {
  const { required, incorrectEmail, incorrectWebsite, minCharacter } =
    errorMessage;

  const getMinCharMessage = (number) =>
    minCharacter.replace('{number}', number);

  return object({
    name: string().required(required),
    industry: string().required(required),
    email: string().email(incorrectEmail).required(required),
    website: string()
      .url(incorrectWebsite)
      .transform((value) => `https://${value}`),
    message: string().required(required)
  });
};
