import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useSignUpPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: signUpPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        errorMessage {
          required
          incorrectEmail
          incorrectWebsite
          minCharacter
        }
      }
      de: deSignUpPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        errorMessage {
          required
          incorrectEmail
          incorrectWebsite
          minCharacter
        }
      }
    }
  `);

  return data[locale];
};
