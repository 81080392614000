import React from 'react';
import { useField } from 'formik';

// External Components

// Local Components
import { InputWrapper } from './InputWrapper';
import { TextArea } from '@thepuzzlers/pieces';

export const TextAreaField = ({ name, id, label, sx, ...props }) => {
  const [field] = useField(name);
  return (
    <InputWrapper
      htmlFor={id}
      label={label}
      name={name}
      sx={{
        ...sx
      }}>
      <TextArea
        id={id}
        {...field}
        rows={6}
        sx={{ resize: 'none' }}
        {...props}
      />
    </InputWrapper>
  );
};
