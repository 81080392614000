import React from 'react';

// External Components
import { Box, Section, Button, Heading, Paragraph } from '@thepuzzlers/pieces';
import { navigate } from 'gatsby';

import { Formik } from 'formik';

import { AnimatePresence } from 'framer-motion';

// Local Components
import { GridSpacer, MarkedText } from 'components';

// Assets
import { WiketLogoWithText, LeftArrow } from 'components/svgs';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { SelectField, TextAreaField, TextField } from 'components/formField';

// Helper Functionn
import { getContactFormValidationSchema } from './getContactFormValidationSchema';
import { fullWidthMinusMargins } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';
import { buttonLabelTransition } from './animation';

export const Header = ({ data, errorMessage }) => {
  return (
    <Section
      id="contact-page"
      sx={{
        position: 'relative',
        height: [null, null, null, null, '100vh', '100vh'],
        gridTemplateRows: [
          null,
          null,
          null,
          null,
          '1fr auto auto auto auto 1fr',
          '1fr auto auto auto auto 1fr'
        ]
      }}>
      <HeadlineBackground />
      <TopSpacer />
      <BackgroundTopSpacer />
      <BackButton label={data.back_button_label} />
      <WiketLogo />
      <TextBlock headline={data.headline} description={data.description} />
      <HeadlineBottomSpacer />
      <FormContainer data={data} errorMessage={errorMessage} />
      <BottomSpacer />
    </Section>
  );
};

const HeadlineBackground = () => {
  return (
    <Box
      className="__headline-background"
      sx={{
        bg: 'gray100',
        gridColumn: [
          '1/13',
          '1/13',
          '1/25',
          '1/25',
          '1 / span 12',
          '1 / span 12'
        ],
        mx: [
          fullWidthMinusMargins[0],
          fullWidthMinusMargins[1],
          fullWidthMinusMargins[2],
          fullWidthMinusMargins[4],
          0,
          0
        ],
        gridRow: [
          '1/ span 4',
          '1/ span 4',
          '1/ span 4',
          '1/ span 4',
          '2 / span 4'
        ],
        borderRadius: [null, null, null, null, 'medium', 'medium']
      }}
    />
  );
};

const BackButton = ({ label }) => {
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Button
      onClick={handleBack}
      variant="clear"
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'primary.normal',
        lineHeight: 1,
        letterSpacing: '-0.01em',
        fontSize: ['1.8rem', '2rem', '2rem', '1.8rem', '2rem', '2rem'],
        gridColumn: [
          '1 / span 4',
          '1/ span 5',
          '1/ span 5',
          '1/ span 5',
          '2/ span 4',
          '2/ span 4'
        ],
        justifySelf: 'start',
        alignSelf: ['center', 'center', 'center', 'center', 'start', 'start'],
        mt: [null, null, null, null, '0.5rem', '0.8rem'],
        gridRow: [2, 2, 2, 2, 3, 3]
      }}>
      <LeftArrow
        sx={{
          width: ['1.2rem', '1.3rem', '1.3rem', '1.2rem', '1.2rem', '1.2rem']
        }}
      />
      <Box
        as="span"
        sx={{
          ml: ['1rem', '1.2rem', '1.2rem', '1rem', '1rem', '1rem'],
          // fix the safari issue to text color
          color: 'text'
        }}>
        {label}
      </Box>
    </Button>
  );
};

const WiketLogo = ({ sx }) => {
  return (
    <WiketLogoWithText
      sx={{
        width: ['8.3rem', '9.3rem', '11rem', '9.6rem', '11rem', '11rem'],
        display: ['block', 'block', 'block', 'block', 'none', 'none'],
        gridColumn: ['8 / 13', '8 / 13', '19 / 25', '9 / 25', null, null],
        justifySelf: 'end',
        alignSelf: 'center',
        gridRow: [2, 2, 2, 2, null, null],
        ...sx
      }}
    />
  );
};

const TextBlock = ({ headline, description }) => {
  return (
    <Box
      className="__text-block"
      sx={{
        gridColumn: [
          '1/ 13',
          '1/ span 11',
          '1/ span 24',
          '1/ span 16',
          '2/ span 9',
          '2/ span 9'
        ],
        mt: ['9.2rem', '14.9rem', '9.4rem', '4.2rem', 0, 0],
        gridRow: [3, 3, 3, 3, 4, 4],
        alignSelf: [null, null, null, null, 'end', 'end']
      }}>
      <WiketLogo
        sx={{
          display: ['none', 'none', 'none', 'none', 'block', 'block']
        }}
      />
      <Heading
        as="h1"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.15,
          fontSize: ['2.8rem', '4rem', '4.8rem', '3.2rem', '3.2rem', '3.2rem'],
          letterSpacing: '-0.01em',
          mt: [null, null, null, null, '4.8rem', '8rem']
        }}
      />
      <Paragraph
        sx={{
          mt: ['2.3rem', '4.4rem', '2.4rem', '2.4rem', '2.4rem', '2.4rem'],
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          fontSize: [
            '1.6rem',
            '1.8rem',
            '1.8rem',
            '1.6rem',
            '1.6rem',
            '1.6rem'
          ],
          letterSpacing: '-0.01em',
          color: 'gray',
          width: ['100%', '38.6rem', '53rem', '39.8rem', '35.8rem', '45.7rem']
        }}>
        {description}
      </Paragraph>
    </Box>
  );
};

const FormContainer = ({ data, errorMessage }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '2 / span 22',
          '1/25',
          '14 / span 10',
          '14 / span 10'
        ],
        mt: ['4.8rem', '10rem', '10.6rem', '7.2rem', 0, 0],
        gridRow: [null, null, null, null, '3 / span 2']
      }}>
      <MarkedText
        data={convertApiHtmlText(data.form_headline.html)}
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.15,
          fontSize: ['2.8rem', '4rem', '4rem', '4rem', '3.2rem', '3.2rem'],
          letterSpacing: '-0.01em',
          textAlign: [null, null, 'center', 'center', 'left', 'left']
        }}
      />
      <FormFields data={data} errorMessage={errorMessage} />
    </Box>
  );
};

const SUBMITTING = 'submitting';
const SUCCESS = 'success';
const ERROR = 'error';

const FormFields = ({
  data: {
    email_label,
    email_placeholder,
    full_name_label,
    full_name_placeholder,
    industry_label,
    message_label,
    message_placeholder,
    website_label,
    website_placeholder,
    form_button_label,
    industry_select_options,
    error_message: globalErrorMessage
  },
  errorMessage
}) => {
  const [formStatus, setFormStatus] = React.useState(null);

  return (
    <Formik
      validationSchema={getContactFormValidationSchema(errorMessage)}
      initialValues={{
        name: '',
        industry: '',
        email: '',
        website: '',
        message: '',
        spamCheck: false
      }}
      onSubmit={async (values) => {
        setFormStatus(SUBMITTING);
        await fetch(
          'https://getform.io/f/7389580f-4dff-415f-9145-a7ade84d5546',
          {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              Accept: 'application/json',
              'content-type': 'application/json'
            }
          }
        )
          .then((res) => {
            if (!res?.ok) {
              setFormStatus(ERROR);
            } else {
              setFormStatus(SUCCESS);
            }
          })
          .catch((err) => {
            setFormStatus(ERROR);
          });
      }}>
      {({ handleSubmit }) => {
        return (
          <Box
            as="form"
            onSubmit={handleSubmit}
            className="__form-field__container"
            sx={{
              display: [null, 'grid', 'grid', 'grid', 'grid', 'grid'],
              gridTemplateColumns: [
                null,
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr'
              ],
              gap: [
                null,
                '3.8rem 1rem',
                '4rem 1.2rem',
                '3.3rem 0.8rem',
                '3.2rem 0.8rem',
                '4rem 1.6rem'
              ],
              mt: ['4rem', '4.8rem', '4.8rem', '3.2rem', '5.8rem', '8.8rem']
            }}>
            <TextField
              name="name"
              id="__form-field__name"
              label={full_name_label}
              placeholder={full_name_placeholder}
            />
            <SelectField
              id="__form-field__industry"
              name="industry"
              label={industry_label}
              options={industry_select_options}
            />
            <TextField
              name="email"
              id="__form-field__email"
              label={email_label}
              placeholder={email_placeholder}
            />
            <TextField
              name="website"
              id="__form-field__website"
              label={website_label}
              placeholder={website_placeholder}
            />
            <TextAreaField
              name="message"
              id="__form-field__message"
              label={message_label}
              placeholder={message_placeholder}
              sx={{
                gridColumn: [
                  null,
                  '1 / span 2',
                  '1 / span 2',
                  '1 / span 2',
                  '1 / span 2',
                  '1 / span 2'
                ]
              }}
            />
            {/* This is form spam protection */}
            <TextField
              name="spamCheck"
              type="checkbox"
              id="__form-field__spam-check"
              sx={{
                display: 'none'
              }}
            />

            <SubmitButtonWithErrorMessage
              label={form_button_label}
              globalErrorMessage={globalErrorMessage}
              formStatus={formStatus}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

const SubmitButtonWithErrorMessage = ({
  label,
  globalErrorMessage,
  formStatus
}) => {
  return (
    <Box
      sx={{
        mt: ['4.2rem', '2.2rem', '4.8rem', '3.2rem', '6.7rem', '4.6rem'],
        gridColumn: [
          null,
          '1 / span 2',
          '1 / span 2',
          '1 / span 2',
          '1 / span 2',
          '1 / span 2'
        ],
        position: 'relative'
      }}>
      {formStatus === ERROR && (
        <GlobalErrorMessage globalErrorMessage={globalErrorMessage} />
      )}
      <Button
        className="__submit-button"
        type="submit"
        variant="primary"
        disabled={formStatus === SUCCESS}
        sx={{
          width: '100%',
          fontSize: ['2rem', '2rem', '2.4rem', '2rem', '2rem', '2rem'],
          fontFamily: 'primary.bold',
          p: ['1.4rem', '1.4rem', '1.8rem', '1.4rem', '1.4rem', '1.8rem'],
          // Animation value
          '&:hover .__label': {
            transform: 'rotate(5deg)'
          },
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          borderColor: formStatus === SUCCESS ? 'transparent' : null,
          transition: 'all 0.2s ease',
          bg: formStatus === SUCCESS ? 'transparent' : null,
          color: formStatus === SUCCESS ? 'primary' : null
        }}>
        {/* Span is needed for animation */}
        <ButtonLabel
          label={label}
          sx={{
            opacity: 0,
            position: 'static'
          }}
        />
        <AnimatePresence>
          <Box
            key={formStatus}
            variants={buttonLabelTransition}
            initial="enter"
            animate="center"
            exit="exit"
            sx={{
              position: 'absolute'
            }}>
            {formStatus === SUBMITTING ? (
              <LoadingLabel />
            ) : formStatus === SUCCESS ? (
              'Sent!'
            ) : (
              <ButtonLabel label={label} />
            )}
          </Box>
        </AnimatePresence>
      </Button>
    </Box>
  );
};

const ButtonLabel = ({ label, sx }) => {
  return (
    <Box
      as="span"
      className="__label"
      sx={{
        // animation value
        transition: 'all 0.2s ease',
        display: 'block',
        ...sx
      }}>
      {label}
    </Box>
  );
};

const LoadingLabel = () => {
  return (
    <Box
      as="span"
      sx={{
        display: 'block'
      }}
      initial={{
        rotate: -5
      }}
      animate={{
        rotate: 5
      }}
      transition={{
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 0.2
      }}>
      Loading...
    </Box>
  );
};

const SentLabel = () => {
  <>Sent!</>;
};

const GlobalErrorMessage = ({ globalErrorMessage }) => {
  return (
    <Paragraph
      sx={{
        position: 'absolute',
        bottom: [
          'calc(100% + 0.5rem)',
          'calc(100% + 1rem)',
          'calc(100% + 1rem)',
          'calc(100% + 1rem)',
          'calc(100% + 1.2rem)',
          'calc(100% + 1.2rem)'
        ],
        left: 0,
        lineHeight: 1.5,
        fontFamily: 'primary.normal',
        fontSize: ['1.3rem', '1.6rem', '1.8rem', '1.6rem', '1.6rem', '1.6rem']
      }}>
      {globalErrorMessage}
    </Paragraph>
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer
      height={['2rem', '4.1rem', '3.9rem', '1.8rem', '2.8rem', '1.8rem']}
      sx={{
        gridRow: 1
      }}
    />
  );
};

const BackgroundTopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '2.7rem', '4.3rem']}
      sx={{
        gridRow: 2,
        display: ['none', 'none', 'none', 'none', 'block', 'block']
      }}
    />
  );
};

const HeadlineBottomSpacer = () => {
  return (
    <GridSpacer
      height={[
        '10.7rem',
        '10rem',
        '10.4rem',
        '7.2rem',
        '7.3rem',
        '5.8rem',
        '6.2rem'
      ]}
      sx={{
        gridRow: [4, 4, 4, 4, 5, 5]
      }}
    />
  );
};

const BottomSpacer = () => {
  return (
    <GridSpacer
      height={['8.5rem', '14.1rem', '17.3rem', '8.5rem', '2.8rem', '1.8rem']}
    />
  );
};
