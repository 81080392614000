import React from 'react';
import { useField } from 'formik';

// External Components
import { Input } from 'theme-ui';

// Local Components
import { InputWrapper } from './InputWrapper';

export const TextField = ({ name, id, type = 'text', label, sx, ...props }) => {
  const [field] = useField(name);
  return (
    <InputWrapper
      htmlFor={id}
      label={label}
      name={name}
      sx={{
        ...sx
      }}>
      <Input id={id} type={type} {...field} {...props} />
    </InputWrapper>
  );
};
