import React from 'react';

// External Components
import { Label, Box } from 'theme-ui';
import { ErrorMessage } from './ErrorMessage';

export const InputWrapper = ({ htmlFor, label, children, name, sx }) => {
  return (
    <Box
      className="input-wrapper"
      sx={{
        '& ~ .input-wrapper': {
          mt: ['3.2rem', 0, 0, 0, 0, 0]
        },
        position: 'relative',
        ...sx
      }}>
      {label && (
        <Label
          htmlFor={htmlFor}
          sx={{
            fontFamily: 'primary.normal',
            m: 0,
            lineHeight: 1,
            fontSize: '1.3rem',
            color: 'gray'
          }}>
          {label}
        </Label>
      )}
      {children}
      <ErrorMessage name={name} />
    </Box>
  );
};
