import React from 'react';

// External Components
import Select from 'react-select';
import { useField } from 'formik';
import { Flex, Label } from 'theme-ui';

// Theme
import { colors } from 'gatsby-plugin-theme-ui/colors';
import { InputWrapper } from './InputWrapper';
import { inputDefault } from 'gatsby-plugin-theme-ui/forms';

export const SelectField = ({ id, label, name, defaultValue, options, sx }) => {
  const [field, meta, helpers] = useField(name);

  const { setValue } = helpers;

  return (
    <InputWrapper
      htmlFor={id}
      label={label}
      name={name}
      sx={{
        fontFamily: inputDefault.fontFamily,
        fontSize: inputDefault.fontSize,
        lineHeight: 1,
        '& .react-select__value-container': {
          p: 0
        },
        '& .react-select__input-container': {
          m: 0,
          p: 0
        },
        '& .react-select__control': {
          minHeight: 'unset',
          p: inputDefault.p,
          border: inputDefault.border,
          borderRadius: inputDefault.borderRadius,
          mt: inputDefault.mt,
          bg: inputDefault.bg
        },
        '& .react-select__indicator': {
          p: 0,
          '& svg': {
            color: 'gray'
          }
        },
        '& .react-select__indicator-separator': {
          display: 'none'
        },
        '& .react-select__placeholder': {
          color: inputDefault['::placeholder'].color
        },
        ...sx
      }}>
      <Select
        className="form__select-field"
        classNamePrefix="react-select"
        name={name}
        options={options}
        isSearchable={false}
        onBlur={() => helpers.setTouched(true)}
        onChange={async (e) => {
          await setValue(e.value);
          helpers.setTouched(true);
        }}
        defaultInputValue={defaultValue}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: colors.secondary,
            primary: colors.primary
          }
        })}
      />
    </InputWrapper>
  );
};
