export const buttonLabelTransition = {
  enter: {
    y: 100
  },
  center: {
    y: 0
  },
  exit: {
    y: -100
  }
};
