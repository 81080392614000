import React from 'react';

import { ErrorMessage as FormikErrorMessage } from 'formik';
import { Paragraph } from '@thepuzzlers/pieces';

export const ErrorMessage = ({ name }) => {
  return (
    <FormikErrorMessage name={name}>
      {(msg) => (
        <Paragraph
          as="p"
          sx={{
            color: 'red',
            fontSize: [
              '1.3rem',
              '1.3rem',
              '1.5rem',
              '1.3rem',
              '1.3rem',
              '1.3rem'
            ],
            lineHeight: 1,
            fontFamily: 'body.normal',
            position: 'absolute',
            top: [
              'calc(100% + 0.5rem)',
              'calc(100% + 0.7rem)',
              'calc(100% + 0.7rem)',
              'calc(100% + 0.6rem)',
              'calc(100% + 0.6rem)',
              'calc(100% + 0.6rem)'
            ],
            left: 0
          }}
          variant="paragraph">
          {msg}
        </Paragraph>
      )}
    </FormikErrorMessage>
  );
};
